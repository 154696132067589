<template>
    <div>
        <modal-tool-q-r-scanner modalId="modalQR" />



        <navbar />
        <breadcrumbs></breadcrumbs>
        
        <div class="container">
            <div class="button-tabs">
                <button class="btn btn-light" @click="showQRModal()">
                    <font-awesome-icon size="3x" icon="camera" class="me-1" />
                    <font-awesome-icon size="3x" icon="qrcode" />
                    </button>
                <router-link v-if="isAdmin" :to="{ name: 'AllTools'}" exact-active-class="btn-primary" class="btn btn-light btn-tab">
                    Svi alati
                </router-link>
                <router-link :to="{ name: 'MyTools'}" :class="{'router-link-active':homePage}" exact-active-class="btn-primary" class="btn btn-light btn-tab">
                    Moji alati
                </router-link>
                <router-link :to="{ name: 'ToolTransfers'}" exact-active-class="btn-primary" class="btn btn-light btn-tab">
                    Razmene
                    <span v-if="transfer_data.length" class="position-absolute translate-middle badge rounded-pill bg-danger request-badge">{{transfer_data.length}}</span>
                </router-link>

                <router-link v-if="isAdmin" :to="{ name: 'ToolDamages'}" exact-active-class="btn-primary" class="btn btn-light btn-tab">
                    Oštećenja
                    <span v-if="allToolDamages.length" class="position-absolute translate-middle badge rounded-pill bg-danger request-badge">{{allToolDamages.length}}</span>
                </router-link>

                <router-link v-if="isAdmin" :to="{ name: 'ToolServices'}" exact-active-class="btn-primary" class="btn btn-light btn-tab">
                    Servisi
                </router-link>
            </div>

            <!-- <transfer-tools-cards :transfers="transfer_data" @update="loadData" /> -->

            <my-tools v-if="homePage" />
            <router-view v-else></router-view>
            <!-- <all-tools /> -->

        </div>

    </div>
    
</template>

<script>

    import Navbar from '@/components/Navbar.vue'
    import ModalToolQRScanner from '@/components/modals/tools/ModalToolQRScanner.vue';
    import {mapState, mapGetters} from 'vuex'
    import { Modal } from 'bootstrap'
    import MyTools from '../components/tools/MyTools.vue';
    import ToolsService from '@/service/ToolsService.js';
    // import TransferToolsCards from '@/components/tools/TransferToolsCards.vue';



    export default {
        components: {
            Navbar,
            ModalToolQRScanner,
            MyTools,
            // TransferToolsCards,
        },

        data() {
            return {
                modalQR: null,

                transfer_data: [],
                allToolDamages: [],
            }
        },

        computed: {
            ...mapState([
                'user',
            ]),

            ...mapGetters([
                'isAdmin',
                'isManager',
            ]),

            homePage() {
                return (this.$route.path === "/alat");
            }
        },

        methods: {
            showQRModal() {
                this.modalQR.show();
            },


            loadMyPendingTransfers() {
                let query = {
                    transfer_status: ["IN_TRANSFER", "REQUESTED"],
                    transfer_participates: "MANAGER",
                }

                ToolsService.getToolTransfer(query).then(async (result)=>{
                    this.transfer_data = result.data
                });
            },

            loadOnlyMinePendingTransfers() {
                let query = {
                    transfer_status: ["IN_TRANSFER", "REQUESTED"],
                    transfer_participates: "ME",
                }

                ToolsService.getToolTransfer(query).then(async (result)=>{
                    this.transfer_data = result.data
                });
            },

            loadAllReportedTools() {
                let query = {
                    status: ["REPORTED"],
                }

                ToolsService.getAllToolsDamages(query).then(async (result)=>{
                    this.allToolDamages = ToolsService.prepareToolsDamageData(result.data)
                });
            },

            loadData() {
                if (this.isManager || this.isAdmin)
                    this.loadMyPendingTransfers();
                else
                    this.loadOnlyMinePendingTransfers();

                this.loadAllReportedTools();

                this.$store.dispatch('loadAwaitingToolTransfers');


            },


        },

        created() {
            this.loadData();
        },

        mounted() {
            this.modalQR = new Modal(document.getElementById('modalQR'));
        }

    }
</script>

<style scoped>
    .button-tabs {
        margin-bottom: 2em;
    }

    .button-tabs > .btn-tab {
        padding: 1em 2em;
        position: relative;
        display: inline-block;
    }

    .button-tabs .router-link-active {
        background: #0d6efd !important;
        color: #fff !important;
    }

    .request-badge {
        position: absolute;
        top: 0px;
        right: -10px;
        font-size: 1em;
        padding: 7px 10px;
        border-radius: 100%;
        background: red;
        color: white;
    }

</style>